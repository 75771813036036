import { Link } from "gatsby";
import React, { useState } from "react";
import { useExternalLinks } from "../hooks/useExternalLinks";
import styled from "@emotion/styled";
import Logo from "../assets/images/mountgold_logo.svg";
import Mountain from "../assets/images/mountain.svg";
import hamburger from "../assets/images/menu_mountgold.svg";
import BreadCrumbs from "./Breadcrumbs";
import { LOCALES, PREFIX } from "../config";

interface HeaderI {
  location: any;
  menu: {
    body: {
      children: any[];
    };
  };
}

const StyledHeader = styled.header`
  position: ${({ open }: any) => (open ? "fixed" : "static")};
  background-color: ${({ open }: any) => (open ? "#441152" : "transparent")};
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: 80px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  @media (min-width: 1250px) {
    justify-content: center;
  }
`;

const LogoLink = styled(Link)`
  margin: ${({ open }: any) => (open ? "auto" : "18px 16px")};
  @media (min-width: 768) {
  }
`;

const Logostyled = styled.img`
  height: 30px;
  @media (min-width: 768px) {
    height: 40px;
  }
`;

const Button = styled.button`
  justify-content: center;
  border: none;
  outline: none;
  background: transparent;
  border: solid 1px #ffc400;
  border-radius: 50%;
  padding: 5px 6px 3px;
  margin: 0 15px 0;
  @media (min-width: 1100px) {
    display: none;
  }
`;

const HeaderMenu = styled.nav`
  display: ${({ open }: any) => (open ? "flex" : "none")};
  flex-wrap: wrap;
  background-color: ${({ open }: any) => (open ? "#441152" : "transparent")};
  flex-direction: column;
  align-items: stretch;
  position: fixed;
  z-index: 99;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;

  @media (min-width: 1250px) {
    display: flex;
    flex-direction: row;
    position: static;
  }
  @media (min-width: 1100px) {
    padding: 0;
  }
`;

const HeaderLink = styled(Link)`
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 1.2px;
  text-align: center;
  color: rgb(255, 196, 0);
  text-decoration: none;
  padding: 8px;
  @media (min-width: 768px) {
    font-size: 16px;
    margin-left: 10px;
    border-bottom: 1px solid transparent;
    &.active {
      border-bottom-color: rgb(255, 196, 0);
    }
  }
`;

const LoginLink: any = styled.a`
  color: #abf422;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 1.2px;
  text-decoration: none;
  border-radius: 500px;
  background-color: #441152;
  border: solid 1px #ffc400;
  color: #ffc400;
  padding: 8px 8px;
  cursor: pointer;
  margin: 6px;
  @media (min-width: 800px) {
    font-size: 16px;
  }
`;

const CashbackLink = styled.div`
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 1.2px;
  text-align: center;
  color: rgb(255, 196, 0);
  text-decoration: none;
  padding: 8px;
  @media (min-width: 768px) {
    font-size: 16px;
    margin-left: 10px;
    border-bottom: 1px solid transparent;
    &.active {
      border-bottom-color: rgb(255, 196, 0);
    }
  }
`;

const Header = ({ location, menu }: HeaderI) => {
  const [open, setOpen] = useState<any>();

  const pageLinks = menu?.body?.children?.filter(
    child => child.__typename === "PageLinkBlock"
  );

  const links = useExternalLinks();
  const affiliateLink = links.find((node: any) => node.title == "Affiliate");
  const parts = location.pathname.split("/");
  const locale = parts.length > 1 && PREFIX.includes(parts[1]) ? parts[1] : "";
  return (
    <StyledHeader open={open}>
      <HeaderContainer>
        <Button onClick={() => setOpen(!open)}>
          <img src={hamburger} alt="Menu" />
        </Button>
        <LogoLink to={locale ? `/${locale}/` : "/"} open={open}>
          <picture>
            <source srcSet={Mountain} media={"(max-width: 500px)"} />
            <Logostyled src={Logo} alt="Mount Gold" />
          </picture>
        </LogoLink>
        <HeaderMenu open={open}>
          {pageLinks?.map(pageLink => {
            return (
              <HeaderLink
                key={pageLink.page.title}
                to={
                  locale
                    ? `/${locale}${pageLink?.page?.path}`
                    : pageLink?.page?.path
                }
                activeClassName="active"
                onClick={() => setOpen(false)}
              >
                {pageLink?.page?.menuTitle}
              </HeaderLink>
            );
          })}
          <CashbackLink onClick={() => setOpen(false)}>
            <LoginLink href="https://www.mountgold.com/cashback/?utm_source=MountgoldEU&utm_medium=cashback%20but&utm_campaign=mountgold-eu">
              Get 10% Cashback
            </LoginLink>
          </CashbackLink>
        </HeaderMenu>
        <LoginLink
          href={affiliateLink.url}
          rel={affiliateLink.rel}
          onClick={() => setOpen(false)}
        >
          {locale === "de_AT" ? "EINLOGGEN" : "LOGIN"}
        </LoginLink>
      </HeaderContainer>
      <BreadCrumbs location={location} menu={menu} />
    </StyledHeader>
  );
};
export default Header;
